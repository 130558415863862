import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Klettenberg: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Klettenberg,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Klettenberg"
          d="M407.4,810.8c-0.3-0.2-0.9-0.4-1.3-0.4c-0.1,0.1-1.3,1.2-1.4,1.3c-0.2-0.2-0.6-0.6-0.8-0.7 c0.2,0.1,3.5-5.7,3.6-5.9c-1.6-1.3-11.1-8.3-13-9.7L444,730l23.3,11.7L430,822h-0.1C410.6,806.5,419.3,820.6,407.4,810.8z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 407.8793 784.038)"
        >
          <tspan x="0" y="0">
            Kletten-
          </tspan>
          <tspan x="0" y="14.4">
            berg
          </tspan>
        </text>
      </g>
    </>
  );
});
